import {
  EStepHook,
  IBeforeFinishCallback,
  IBeforeFinishOutput,
  IBulkRowHook,
  IColumnHook,
  IColumnHookInput,
  IColumnHookOutput,
  IPublicConnectionMethods,
  IResultMetadataWithValues,
  IReviewStepData,
  IReviewStepPostHooksData,
  IRow,
  IRowCell,
  IRowCellBasic,
  IRowDeleteHook,
  IRowHook,
  IRowHookCellBasic,
  IRowHookInput,
  IRowHookOutput,
  IRowHookOutputInternal,
  IStepHook,
  ITableMessage,
  IUploadStepData,
} from "./interfaces";

export type HookErrorHandlerFn = (err: unknown, hookType: string) => void;
const MAX_HOOK_TIME_MS = 10000;

export const consoleErrorHandler: HookErrorHandlerFn = (
  err: unknown,
  hookType: string
) =>
  console.error(
    `[Dromo-External-Error] There was an error running your ${hookType}.`,
    err
  );

const setHookTimer = (hookType: string) => {
  return setTimeout(() => {
    // eslint-disable-next-line no-console
    console.warn(
      `[Dromo Error] Slow ${hookType} detected (>10s). Long running hooks can degrade user experience and importer performance.`
    );
  }, MAX_HOOK_TIME_MS);
};

const clearHookTimer = (timeoutId: NodeJS.Timeout) => {
  clearTimeout(timeoutId);
};

export const executeColumnHooks = async (
  hooks: IColumnHook[],
  data: IColumnHookInput[],
  errorHandler: HookErrorHandlerFn
): Promise<IColumnHookOutput[]> => {
  const changes = new Map<
    number,
    { newValue?: string; info?: ITableMessage[] }
  >();
  // Each hook call updates this tmpData object with the latest values.
  // This allows us to run hooks sequentially with each subsequent call
  // having the most up to date data
  const tmpData: IColumnHookInput[] = data.map((d) => ({ ...d }));

  // For each registered hook, call the developer defined callback
  await hooks.reduce(async (previousHookPromise, currentHook) => {
    await previousHookPromise.then(async () => {
      try {
        const timerId = setHookTimer("column hook");
        const hookResponse = await currentHook.callback(tmpData);
        clearHookTimer(timerId);
        hookResponse.forEach((hookOutput: IColumnHookOutput) => {
          // If the user has updated the value or info for a particular
          // index update the changes map, and the tmpData
          if (hookOutput.value !== undefined || hookOutput.info !== undefined) {
            const change: {
              value?: string;
              info?: ITableMessage[];
            } = {};
            if (hookOutput.value !== undefined) {
              change.value = hookOutput.value;
              tmpData[hookOutput.index].value = hookOutput.value;
            }
            if (hookOutput.info !== undefined) change.info = hookOutput.info;
            changes.set(hookOutput.index, change);
          }
        });
      } catch (err) {
        errorHandler(err, "column hooks");
      }
    });
  }, Promise.resolve());

  // Change the map into IColumnHookOuput for the application to process
  const columnHookOutput: IColumnHookOutput[] = [];
  changes.forEach((change, index) =>
    columnHookOutput.push({ ...change, index })
  );
  return columnHookOutput;
};

const fieldOutputEmpty = (fieldOutput: IRowCell): boolean =>
  fieldOutput.value === undefined &&
  fieldOutput.info === undefined &&
  fieldOutput.selectOptions === undefined &&
  fieldOutput.manyToOne === undefined;

export const executeRowHooks = async (
  data: IRowHookInput[],
  mode: "init" | "update",
  rowHooks: IRowHook[],
  bulkRowHooks: IBulkRowHook[],
  errorHandler: HookErrorHandlerFn
): Promise<IRowHookOutputInternal[]> => {
  // Changes is the final changes that will be sent to the frontend
  const changes = new Map<number, IRow>();
  const inputRowMap = new Map(
    data.map((inputRow) => [inputRow.index, inputRow])
  );

  // a helper function called for each row that updates the final changes
  // as well as the row data which will be passed to subsequent hooks
  const mutateInputDataWithChanges = (
    rowIndex: number,
    rowOutput: IRowHookOutput["row"]
  ) => {
    const inputRow = inputRowMap.get(rowIndex)!;

    for (const [fieldName, fieldOutput] of Object.entries(rowOutput ?? {})) {
      if (fieldOutputEmpty(fieldOutput as IRowCell)) continue;

      const inputRowField = inputRow.row[fieldName];
      const inputFieldIsManyToOne = Array.isArray(inputRowField.manyToOne);
      const outputFieldIsManyToOne =
        "manyToOne" in fieldOutput && Array.isArray(fieldOutput.manyToOne);

      if (inputFieldIsManyToOne && !outputFieldIsManyToOne) {
        throw new Error(
          `${fieldName} should have manyToOne defined as an array.`
        );
      } else if (
        inputFieldIsManyToOne &&
        outputFieldIsManyToOne &&
        inputRowField.manyToOne!.length !== fieldOutput.manyToOne.length
      ) {
        throw new Error(
          `${fieldName} should be array of length ${
            inputRowField.manyToOne!.length
          }`
        );
      }

      const rowChanges = changes.get(rowIndex) || {};
      const cellChange: IRowCell = rowChanges[fieldName] ?? {};

      const fieldChanges: {
        fieldOutput: IRowCellBasic;
        rowDataField: IRowHookCellBasic;
        cellChange: IRowCellBasic;
      }[] = [];

      if (outputFieldIsManyToOne) {
        cellChange.manyToOne = [];

        fieldOutput.manyToOne.forEach((fieldOutputCell, index) => {
          if (!fieldOutputEmpty(fieldOutputCell as IRowCell)) {
            const manyToOneCellChange: IRowCellBasic = {};
            cellChange.manyToOne!.push(manyToOneCellChange);
            fieldChanges.push({
              fieldOutput: fieldOutputCell,
              rowDataField: inputRowField.manyToOne![index],
              cellChange: manyToOneCellChange,
            });
          }
        });
      } else {
        fieldChanges.push({
          fieldOutput: fieldOutput as IRowCellBasic,
          rowDataField: inputRowField,
          cellChange,
        });
      }
      // if there are any changes, we want to both update the changes
      // object sent to the app, and the rowInput sent to subsequent
      // row hooks running on this row so they see the changes from
      // prior row hooks
      fieldChanges.forEach(
        ({ fieldOutput, rowDataField: inputRowDataField, cellChange }) => {
          if (fieldOutput.value !== undefined) {
            cellChange.value = fieldOutput.value;
            inputRowDataField.value = fieldOutput.value;
          }

          if (fieldOutput.info !== undefined) {
            cellChange.info = fieldOutput.info;
            inputRowDataField.info = fieldOutput.info;
          }

          if (fieldOutput.selectOptions !== undefined) {
            cellChange.selectOptions = fieldOutput.selectOptions;
            inputRowDataField.selectOptions = fieldOutput.selectOptions;
          }
        }
      );
      rowChanges[fieldName] = cellChange;
      changes.set(rowIndex, rowChanges);
    }
  };
  // First we run the bulk row hooks with all of the input data
  for (const bulkRowHook of bulkRowHooks) {
    try {
      const timerId = setHookTimer("bulk row hook");
      const hookOutput = await bulkRowHook(data, mode);
      clearHookTimer(timerId);
      for (const rowOutput of hookOutput) {
        mutateInputDataWithChanges(rowOutput.index, rowOutput.row);
      }
    } catch (err) {
      errorHandler(err, "bulk row hooks");
    }
  }

  // We want to loop through each row independently. Because each
  // row's execution is independent, we can do row by row first vs
  // hook by hook
  await Promise.all(
    data.map(async (rowInput) => {
      for (const rowHook of rowHooks) {
        try {
          const timerId = setHookTimer("row hook");
          const hookOutput = await rowHook(rowInput, mode);
          clearHookTimer(timerId);
          mutateInputDataWithChanges(rowInput.index, hookOutput.row);
        } catch (err) {
          errorHandler(err, "row hooks");
        }
      }
    })
  );

  const finalChanges: IRowHookOutputInternal[] = [];

  changes.forEach((rowChanges, rowIndex) => {
    finalChanges.push({ index: rowIndex, row: rowChanges });
  });
  return finalChanges;
};

export const executeRowDeleteHooks = async (
  deletedRows: IRowHookInput[],
  rowDeleteHooks: IRowDeleteHook[],
  errorHandler: HookErrorHandlerFn
): Promise<void[]> => {
  // We want to loop through each row independently. Because each
  // row's execution is independent, we can do row by row first vs
  // hook by hook
  return Promise.all(
    deletedRows.map(async (rowData) => {
      rowDeleteHooks.forEach(async (currentHook) => {
        try {
          const timerId = setHookTimer("row delete hook");
          await currentHook(rowData);
          clearHookTimer(timerId);
        } catch (err) {
          errorHandler(err, "row delete hooks");
        }
      });
    })
  );
};

export const executeStepHooks = async (
  step: EStepHook,
  data: IUploadStepData | IReviewStepData | IReviewStepPostHooksData,
  stepHooks: IStepHook[],
  instance: IPublicConnectionMethods,
  errorHandler: HookErrorHandlerFn
) => {
  await Promise.all(
    stepHooks.map(async (hook) => {
      if (step !== hook.type) return Promise.resolve();

      try {
        return await hook.callback(instance, data);
      } catch (err) {
        errorHandler(err, `step hook ${step}`);
        return Promise.resolve();
      }
    })
  );
};

export const executeBeforeFinishCallback = async (
  data: Record<string, unknown>[],
  metadata: IResultMetadataWithValues,
  beforeFinishCallback: IBeforeFinishCallback,
  instance: IPublicConnectionMethods,
  errorHandler: HookErrorHandlerFn
): Promise<IBeforeFinishOutput> => {
  try {
    return await beforeFinishCallback(data, metadata, instance);
  } catch (err) {
    errorHandler(err, "before finish callback");
  }
};
