import { IFRAME_URL, V1_IFRAME_URL } from "./constants";
import { connectToChild } from "penpal";
import { Connection } from "penpal/lib/types";

import {
  ITableMessage,
  IMessagesForCell,
  IColumnHook,
  IColumnHookInput,
  IColumnHookOutput,
  IRowHook,
  IRowHookInput,
  IRowHookOutput,
  IDeveloperField,
  IDeveloperSettings,
  IDeveloperStyleOverrides,
  IUser,
  IValidatorField,
  IRowHookOutputInternal,
  IResultMetadata,
  EStepHook,
  IReviewStepData,
  IStepHook,
  IUploadStepData,
  IRowDeleteHook,
  IBulkRowHook,
  IBeforeFinishCallback,
  IBeforeFinishOutput,
  IPositionSpec,
  IConnectionMethods,
  ISelectField,
  IImporterOptions,
  IAllHooks,
  IRowToAdd,
  IResultMetadataWithValues,
} from "./interfaces";
import {
  consoleErrorHandler,
  executeBeforeFinishCallback,
  executeColumnHooks,
  executeRowDeleteHooks,
  executeRowHooks,
  executeStepHooks,
} from "./executeHooks";

const DROMO_WRAPPER_SUFFIX = "dromo-container";
const IFRAME_SUFFIX = "dromo-iframe-element";

declare global {
  interface Window {
    DROMO_WIDGET_OVERRIDE?: string;
  }
}

const makeFieldSerializable = (field: IDeveloperField) => {
  const newField = { ...field };
  if (newField.validators) {
    newField.validators = newField.validators.map((validator) => {
      if (
        (validator.validate === "regex_match" ||
          validator.validate === "regex_exclude") &&
        validator.regex instanceof RegExp
      ) {
        return {
          ...validator,
          regex: validator.regex.source,
          regexOptions: {
            ignoreCase: validator.regex.flags.includes("i"),
            dotAll: validator.regex.flags.includes("s"),
            multiline: validator.regex.flags.includes("m"),
            unicode: validator.regex.flags.includes("u"),
          },
        };
      }
      return validator;
    });
  }
  return newField;
};

export default class DromoUploader {
  // Unique IDs for iframe and container
  /** @internal */
  iframeId: string;
  /** @internal */
  wrapperId: string;
  /** @internal */
  embedInline: boolean = false;

  // Refers to unique backend license key
  /** @internal */
  licenseKey: string;
  // See DeveloperFieldsSchema
  /** @internal */
  fields: IDeveloperField[] | undefined;
  // See DeveloperSettingsSchema
  /** @internal */
  settings: IDeveloperSettings | undefined;
  // See UserSchema
  /** @internal */
  user: IUser | undefined;
  // If the user is using a saved schema, its ID
  // (Could also be a schema name for backwards compatibility)
  /** @internal */
  schemaId: string | undefined;
  // DevelopmentMode
  /** @internal */
  developmentMode: boolean | undefined;
  // Header Row Override
  /** @internal */
  headerRowOverride: number | null | undefined;

  // HTML references
  /** @internal */
  iframe: HTMLIFrameElement | undefined;
  /** @internal */
  wrapper: HTMLElement | undefined;

  // Penpal connection reference
  /** @internal */
  connection: Connection<IConnectionMethods> | undefined;

  // Data Hooks
  /** @internal */
  columnHooks: IColumnHook[] = [];
  /** @internal */
  bulkRowHooks: IBulkRowHook[] = [];
  /** @internal */
  rowHooks: { callback: IRowHook }[] = [];
  /** @internal */
  stepHooks: IStepHook[] = [];
  /** @internal */
  rowDeleteHooks: IRowDeleteHook[] = [];

  // Callbacks
  /** @internal */
  beforeFinishCallback: IBeforeFinishCallback | undefined;
  /** @internal */
  resultsCallback:
    | ((data: any, metadata: IResultMetadata) => Promise<void> | void)
    | undefined;

  /** @internal */
  cancelCallback: (() => void) | undefined;

  /** @internal */
  appHost: string;

  constructor(
    licenseKey: string,
    fields: IDeveloperField[],
    settings: IDeveloperSettings,
    user: IUser
  );

  constructor(licenseKey: string, schemaId: string);

  constructor(
    licenseKey: string,
    fieldsOrSchemaId: IDeveloperField[] | string,
    settings?: IDeveloperSettings,
    user?: IUser
  ) {
    this.iframeId =
      Math.random().toString(36).substring(7) + "-" + IFRAME_SUFFIX;
    this.wrapperId =
      Math.random().toString(36).substring(7) + "-" + DROMO_WRAPPER_SUFFIX;
    this.appHost = document.location.hostname;

    if (settings !== undefined && user !== undefined) {
      this.licenseKey = licenseKey;
      this.fields = (fieldsOrSchemaId as IDeveloperField[]).map(
        makeFieldSerializable
      );
      this.settings = settings;
      this.user = user;

      this.checkCloneable({
        licenseKey: this.licenseKey,
        fields: this.fields,
        settings: this.settings,
        user: this.user,
      });
    } else {
      this.licenseKey = licenseKey;
      this.schemaId = fieldsOrSchemaId as string;

      this.checkCloneable({
        licenseKey: this.licenseKey,
        schemaId: this.schemaId,
      });
    }
  }

  initChild = async () => {
    if (!this.connection) {
      console.error("shim cannot connect with Dromo service.");
      alert(
        "There was en error opening the importer. Please contact support if this issue persists."
      );
      return;
    }

    const child = await this.connection.promise;

    // We check to see if this was loaded from either an SDK or snippet that didn't specify a version
    // If it was, this is a legacy snippet/sdk and we should NOT show the redesigned UI
    const loadedFromUnVersionedScript = Array.from(
      document.getElementsByTagName("script")
    ).some(
      (s) =>
        s.src === "https://unpkg.com/dromo-uploader-js/dist/DromoUploader.js" ||
        s.src ===
          "https://cdn.jsdelivr.net/npm/dromo-uploader-js@latest/dist/DromoUploader.js"
    );

    // We need to register the hooks with the widget before we init, because
    // init might go directly to the review screen and we need to make sure
    // the hooks are already registered at that point
    await child.setNumRegisteredRowHooks(this.totalNumRowHooks());
    await child.setNumRegisteredColHooks(this.columnHooks.length);
    await child.setNumRegisteredRowDeleteHooks(this.rowDeleteHooks.length);
    await child.setEmbedInline(this.embedInline);

    if (this.fields && this.settings && this.user) {
      const settings = { ...this.settings };
      if (!loadedFromUnVersionedScript) {
        settings.version = settings.version ?? "v2";
      }
      settings.browserExcelParsing = settings.browserExcelParsing ?? true;

      await child.init(
        this.licenseKey,
        this.fields,
        settings,
        this.user,
        this.appHost
      );
    } else if (this.schemaId) {
      await child.initFromSavedSchema(
        this.licenseKey,
        this.schemaId,
        this.appHost,
        {
          user: this.user,
          developmentMode: this.developmentMode,
          headerRowOverride: this.headerRowOverride,
        }
      );
    } else {
      console.error("Invalid Dromo configuration");
    }
  };

  /**
   * Creates the iframe object
   * @internal
   */
  initIFrame = () => {
    let widgetUrl: string;

    if (window.DROMO_WIDGET_OVERRIDE) {
      widgetUrl = window.DROMO_WIDGET_OVERRIDE;
    } else if (this.settings?.alternateDomain) {
      const url = new URL(this.settings.alternateDomain);
      widgetUrl = url.protocol + "//widget." + url.host;
    } else if (this.settings?.version === "v1") {
      widgetUrl = V1_IFRAME_URL;
    } else {
      widgetUrl = IFRAME_URL;
    }

    if (!document.getElementById(this.iframeId)) {
      const iframe = document.createElement("iframe");
      const identifier = this.settings?.importIdentifier ?? this.schemaId;
      iframe.title = identifier
        ? `Dromo Importer: ${identifier}`
        : "Dromo Importer";
      iframe.src = widgetUrl;
      iframe.id = this.iframeId;
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.border = "0";
      // @ts-ignore
      iframe.crossorigin = "anonymous";
      this.iframe = iframe;
    }
  };

  /**
   * Uses Penpal to create a connection with the iframe that was created
   * @internal
   */
  createConnection = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const iframe = this.iframe;

    if (!iframe) {
      console.error("shim cannot connect to Dromo.");
      return;
    }
    this.connection = connectToChild<IConnectionMethods>({
      iframe,
      methods: {
        /**
         * Called for each column/field. Sends all data and expects all the
         * relevant column hooks to be evaluated.
         *
         * @param fieldName the fieldname key for the column
         * @param data the full column of data for the particular key
         */
        async handleColumnHooks(
          fieldName: string,
          data: IColumnHookInput[]
        ): Promise<IColumnHookOutput[]> {
          const hooks = self.columnHooks.filter(
            (h) => h.fieldName === fieldName
          );
          return await executeColumnHooks(hooks, data, consoleErrorHandler);
        },

        /**
         * Called singularly for each row. Goes through all row hooks and
         * evaluates them.
         *
         * @param data an array of row data objects
         */
        async handleRowHooks(
          data: IRowHookInput[],
          mode: "init" | "update"
        ): Promise<IRowHookOutputInternal[]> {
          return await executeRowHooks(
            data,
            mode,
            self.rowHooks.map((rh) => rh.callback),
            self.bulkRowHooks,
            consoleErrorHandler
          );
        },
        async handleStepHook(
          step: EStepHook,
          data: IUploadStepData | IReviewStepData
        ) {
          return await executeStepHooks(
            step,
            data,
            self.stepHooks,
            self,
            consoleErrorHandler
          );
        },
        /**
         * Called singularly for each row. Goes through all delete hooks and
         * evaluates them.
         *
         * @param deletedRows - an array of rows deleted from the data
         */
        async handleRowDeleteHooks(
          deletedRows: IRowHookInput[]
        ): Promise<void> {
          // We want to loop through each row independently. Because each
          // row's execution is independent, we can do row by row first vs
          // hook by hook
          await executeRowDeleteHooks(
            deletedRows,
            self.rowDeleteHooks,
            consoleErrorHandler
          );
        },
        async handleBeforeFinishCallback(
          data: Record<string, any>[],
          metadata: IResultMetadataWithValues
        ): Promise<IBeforeFinishOutput> {
          if (self.beforeFinishCallback) {
            return await executeBeforeFinishCallback(
              data,
              metadata,
              self.beforeFinishCallback,
              self,
              consoleErrorHandler
            );
          }
        },
        /**
         * Called after the user has completed the Dromo flow, send the
         * cleaned data to the developer
         *
         * TODO: data cannot be of type any. We need to standardize our return
         * type to be Map<string, any>[]
         * @param data cleaned data after the flow is complete
         */
        async handleResults(
          data: any,
          metadata: IResultMetadata
        ): Promise<void> {
          if (self.resultsCallback) {
            try {
              await self.resultsCallback(data, metadata);
            } catch (err) {
              console.error(
                "[Dromo-External-Error] There was an error in your onResult callback.",
                err
              );
            }
          }
          self.close();
        },
        /**
         * Called when the modal has been closed by the finishing of the flow
         */
        handleCloseModal() {
          self.close();
        },
        /**
         * Called when the modal has been closed due to the user canceling the
         * flow
         */
        handleCancel() {
          if (self.cancelCallback) {
            try {
              self.cancelCallback();
            } catch (err) {
              console.error(
                "[Dromo-External-Error] There was an error in your cancel callback.",
                err
              );
            }
          }
          self.close();
        },
      },
    });
  };

  /**
   * Mounts the iframe in the parent HTML page
   * NOTE: Should be called AFTER the PenPal connection has been created
   * @internal
   */
  mountIFrame = () => {
    if (!document.getElementById(this.iframeId!)) {
      const wrapper = document.createElement("div");
      wrapper.id = this.wrapperId;
      wrapper.style.zIndex = Number.MAX_SAFE_INTEGER.toString();
      wrapper.style.visibility = "hidden";
      wrapper.style.width = "0px";
      wrapper.style.height = "0px";
      wrapper.style.position = "fixed";
      wrapper.style.top = "0px";
      wrapper.style.left = "0px";
      wrapper.style.right = "0px";
      wrapper.style.bottom = "0px";
      wrapper.appendChild(this.iframe as HTMLIFrameElement);
      document.body.appendChild(wrapper);
      this.wrapper = wrapper;
    }
  };

  addIFrameToWrapper = () => {
    if (this.wrapper && this.iframe) {
      this.iframe.style.visibility = "hidden";
      this.wrapper.appendChild(this.iframe);
    }
  };

  showIframe = () => {
    if (this.iframe) {
      this.iframe.style.visibility = "visible";
    }
  };

  showWrapper = () => {
    if (this.wrapper) {
      this.wrapper.style.visibility = "visible";
      this.wrapper.style.width = "100%";
      this.wrapper.style.height = "100%";
      document.body.style.overflow = "hidden";
    }
  };

  /**
   * Function called by the developer to set a callback
   *
   * @param fieldName the key of the column for which we want to trigger the
   * callback
   * @param callback developer defined callback that is callback that is added
   * to a list of column hooks
   */
  registerColumnHook = (
    fieldName: IColumnHook["fieldName"],
    callback: IColumnHook["callback"]
  ) => {
    this.columnHooks.push({
      fieldName: fieldName,
      callback: callback,
    });

    // This is kept for the JS version - a user can register after the connection
    // has been made and the modal has been opened. It's bad form, but possible
    if (this.connection) {
      this.connection.promise.then((child) => {
        child.setNumRegisteredColHooks(this.columnHooks.length);
      });
    }
  };

  /** @internal */
  totalNumRowHooks = () => {
    return this.rowHooks.length + this.bulkRowHooks.length;
  };

  /**
   * Function called by the developer to set a callback
   *
   * @param callback developer defined callback that is added to a list of row
   * hooks
   */
  registerBulkRowHook = (bulkRowHook: IBulkRowHook) => {
    this.bulkRowHooks.push(bulkRowHook);

    // This is kept for the JS version - a user can register after the connection
    // has been made and the modal has been opened. It's bad form, but possible
    if (this.connection) {
      this.connection.promise.then((child) => {
        child.setNumRegisteredRowHooks(this.totalNumRowHooks());
      });
    }
  };

  /**
   * Function called by the developer to set a callback
   *
   * @param callback developer defined callback that is added to a list of row
   * hooks
   */
  registerRowHook = (callback: IRowHook) => {
    this.rowHooks.push({
      callback: callback,
    });

    // This is kept for the JS version - a user can register after the connection
    // has been made and the modal has been opened. It's bad form, but possible
    if (this.connection) {
      this.connection.promise.then((child) => {
        child.setNumRegisteredRowHooks(this.totalNumRowHooks());
      });
    }
  };

  /**
   * Function called by the developer to set a callback
   *
   * @param callback developer defined callback that is called per step of the
   * import flow
   */
  registerStepHook = (
    type: IStepHook["type"],
    callback: IStepHook["callback"]
  ) => {
    this.stepHooks.push({ type, callback });
  };

  /**
   * Function called by the developer to set a row delete callback
   *
   * @param callback developer defined callback that is called per step of the
   * import flow
   */
  registerRowDeleteHook = (callback: IRowDeleteHook) => {
    this.rowDeleteHooks.push(callback);
    // This is kept for the JS version - a user can register after the connection
    // has been made and the modal has been opened. It's bad form, but possible
    if (this.connection) {
      this.connection.promise.then((child) => {
        child.setNumRegisteredRowDeleteHooks(this.rowDeleteHooks.length);
      });
    }
  };

  /**
   * Function called by the developer to set the beforeFinish callback
   *
   * @param callback developer defined callback that is called when the user submits
   */
  beforeFinish = (callback: IBeforeFinishCallback): void => {
    this.beforeFinishCallback = callback;
  };

  /**
   * Convenience function for setting all hooks at once.
   *
   * @param hooks object containing all hooks to register
   */
  registerAllHooks = (hooks: IAllHooks): void => {
    if (hooks.columnHooks) {
      for (const { fieldName, callback } of hooks.columnHooks) {
        this.registerColumnHook(fieldName, callback);
      }
    }

    if (hooks.rowHooks) {
      for (const rowHook of hooks.rowHooks) {
        this.registerRowHook(rowHook);
      }
    }

    if (hooks.bulkRowHooks) {
      for (const bulkRowHook of hooks.bulkRowHooks) {
        this.registerBulkRowHook(bulkRowHook);
      }
    }

    if (hooks.rowDeleteHooks) {
      for (const rowDeleteHook of hooks.rowDeleteHooks) {
        this.registerRowDeleteHook(rowDeleteHook);
      }
    }

    if (hooks.stepHooks) {
      for (const { type, callback } of hooks.stepHooks) {
        this.registerStepHook(type, callback);
      }
    }

    if (hooks.beforeFinishCallback) {
      this.beforeFinish(hooks.beforeFinishCallback);
    }
  };

  /**
   * Function called by the developer to set a callback
   *
   * @param callback developer defined callback that is called when the import
   * flow completes
   */
  onResults = (
    callback: (
      data: Record<string, string | number | boolean | null>[],
      metadata: IResultMetadata
    ) => Promise<void> | void
  ) => {
    this.resultsCallback = callback;
  };

  /**
   * Function called by the developer to set a callback
   *
   * @param callback developer defined callback that is called when the user
   * cancels the import
   */
  onCancel = (callback: () => void) => {
    this.cancelCallback = callback;
  };

  /**
   * Function called by the developer to set or reset the user information
   */
  setUser = async (user: IUser) => {
    this.user = user;
    if (this.connection) {
      const child = await this.connection!.promise;
      await child.setUser(this.user);
    }
  };

  /**
   * Function called by the developer to add a field midway through the import
   * process
   */
  addField = async (field: IDeveloperField, position?: IPositionSpec) => {
    if (this.connection) {
      const child = await this.connection!.promise;
      await child.addField(makeFieldSerializable(field), position);
    } else {
      console.error("[Dromo-Error] Invalid connection to Dromo Uploader.");
    }
  };

  /**
   * Function called by the developer to remove a field. The data won't appear
   * in the user interface nor in the result data.
   */
  removeField = async (fieldKey: string) => {
    if (this.connection) {
      const child = await this.connection!.promise;
      await child.removeField(fieldKey);
    } else {
      console.error("[Dromo-Error] Invalid connection to Dromo Uploader.");
    }
  };

  /**
   * Function called by the developer to add new table messages throughout the
   * import process
   */
  updateInfoMessages = async (messages: IMessagesForCell[]) => {
    if (this.connection) {
      const child = await this.connection!.promise;
      await child.updateInfoMessages(messages);
    } else {
      console.error("[Dromo-Error] Invalid connection to Dromo Uploader.");
    }
  };

  /**
   * Function called by the developer to change the matchingStep.headerRowOverride
   * setting from a step hook
   */
  setHeaderRowOverride = async (headerRowOverride: number | null) => {
    this.headerRowOverride = headerRowOverride;
    if (this.connection) {
      const child = await this.connection!.promise;
      await child.setHeaderRowOverride(headerRowOverride);
    }
  };

  /**
   * Function called by developer called to open the iframe
   */
  open = async () => {
    if (!this.wrapper) {
      this.initIFrame();
      this.createConnection();
      this.mountIFrame();
    }

    await this.initChild();
    this.showWrapper();
  };

  initInline = async (wrapperIdOrElement: string | HTMLElement) => {
    this.embedInline = true;

    try {
      if (wrapperIdOrElement instanceof HTMLElement) {
        this.wrapper = wrapperIdOrElement;
        this.wrapperId = wrapperIdOrElement.id;

        if (this.wrapperId === "" || this.wrapperId === undefined) {
          this.wrapperId =
            Math.random().toString(36).substring(7) +
            "-" +
            DROMO_WRAPPER_SUFFIX;
        }
      }

      if (typeof wrapperIdOrElement === "string") {
        const wrapperEl = document.getElementById(wrapperIdOrElement);

        if (wrapperEl === null) {
          throw new Error("WRAPPER_NOT_FOUND");
        }

        this.wrapper = wrapperEl;
        this.wrapperId = wrapperIdOrElement;
      }

      if (!this.iframe) {
        this.initIFrame();
        this.createConnection();
        this.addIFrameToWrapper();
      }

      await this.initChild();
      this.showIframe();
    } catch (error) {
      if (error.message === "WRAPPER_NOT_FOUND") {
        console.error(
          "[Dromo-Error] Could not find wrapper using provided ID."
        );
      }

      throw error;
    }
  };

  /**
   * Function called by the developer to add rows during the import
   */
  addRows = async (rows: IRowToAdd[]) => {
    if (this.connection) {
      const child = await this.connection!.promise;
      await child.addRows(rows);
    } else {
      console.error("[Dromo-Error] Invalid connection to Dromo Uploader.");
    }
  };

  /**
   * Function called by the developer to remove rows
   */
  removeRows = async (rowIds: string[]) => {
    if (this.connection) {
      const child = await this.connection!.promise;
      await child.removeRows(rowIds);
    } else {
      console.error("[Dromo-Error] Invalid connection to Dromo Uploader.");
    }
  };

  /**
   * Function called by developer to close the iframe
   */
  close = () => {
    if (this.wrapper) {
      this.wrapper.style.visibility = "hidden";
      this.wrapper.style.width = "0px";
      this.wrapper.style.height = "0px";
      document.body.style.overflow = "inherit";
    }
  };

  /**
   * Function called by the developer to set developmentMode
   */
  setDevelopmentMode = async (developmentMode: boolean) => {
    this.developmentMode = developmentMode;
    if (this.connection) {
      const child = await this.connection!.promise;
      await child.setDevelopmentMode(this.developmentMode);
    }
  };

  /**
   * @internal
   */
  static rehydrateHeadless = async ({
    licenseKey,
    headlessImportId,
    fields,
    settings,
    hooks,
    rehydrateState,
  }: {
    licenseKey: string;
    headlessImportId: string;
    fields: IDeveloperField[];
    settings: IDeveloperSettings;
    hooks: IAllHooks;
    rehydrateState: any;
  }): Promise<DromoUploader> => {
    const headlessUser: IUser = { id: "headless-review" };
    const dromo = new DromoUploader(licenseKey, fields, settings, headlessUser);
    dromo.registerAllHooks(hooks);
    await dromo.rehydrate(rehydrateState, headlessImportId);
    dromo.open();
    return dromo;
  };

  /**
   * @internal
   */
  rehydrate = async (rehydrateState: any, headlessImportId: string) => {
    if (!this.wrapper) {
      this.initIFrame();
      this.createConnection();
      this.mountIFrame();
    }
    await this.initChild();
    const child = await this.connection!.promise;
    await child.rehydrate(rehydrateState, headlessImportId);
    this.showWrapper();
  };

  /**
   * @internal
   */
  checkCloneable(values: Record<string, any>): void {
    if (typeof structuredClone !== "function") return;

    const notCloneable = Object.entries(values)
      .filter(([_name, value]) => {
        try {
          structuredClone(value);
          return false;
        } catch (e) {
          return true;
        }
      })
      .map(([name]) => name);

    if (notCloneable.length > 0) {
      const badValues = notCloneable.join(", ");
      throw new Error(
        `[Dromo-External-Error] The Dromo importer received non-cloneable parameters: ${badValues}. See https://dromo.dev/err/clone`
      );
    }
  }
}

export {
  ITableMessage,
  IMessagesForCell,
  IColumnHook,
  IColumnHookInput,
  IColumnHookOutput,
  IRowHook,
  IRowHookInput,
  IRowHookOutput,
  IDeveloperField,
  IDeveloperSettings,
  IDeveloperStyleOverrides,
  IUser,
  IValidatorField,
  IRowHookOutputInternal,
  IResultMetadata,
  IReviewStepData,
  IStepHook,
  IUploadStepData,
  IRowDeleteHook,
  IBulkRowHook,
  IBeforeFinishCallback,
  IBeforeFinishOutput,
  IPositionSpec,
  IConnectionMethods,
  ISelectField,
  IImporterOptions,
  IAllHooks,
};
